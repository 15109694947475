import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/Index.vue"),
    redirect: "/channel"
  },
  // {
  //   path: "*",
  //   redirect: "/channel?type=1"
  // },
  {
    path: "/floorPage",
    name: "FloorPage",
    redirect: "/floorPage/page1",
    component: () => import("../views/floorPage"),
    children: [
      {
        path: "page1/:status",
        name: "Page1",
        meta: {
          title: "1号奢仓"
        },
        component: () => import("../views/floorPage/page1.vue")
      },
      {
        path: "page2",
        name: "Page2",
        meta: {
          title: "1号奢仓"
        },
        component: () => import("../views/floorPage/page2.vue")
      }
    ]
  },
  {
    path: "/channel",
    name: "Channel",
    meta: {
      title: "1号奢仓"
    },
    component: () => import("./../views/channel/channel.vue")
  },
  {
    path: "/pointLottery",
    name: "Channel",
    meta: {
      title: "1号奢仓"
    },
    component: () => import("./../views/pointsMall/pointLottery.vue")
  },
  {
    path: "/blindBox",
    name: "blindBox",
    meta: {
      title: "1号奢仓"
    },
    component: () => import("./../views/blindBox/index.vue")
  },
  {
    path: "/cardPage",
    name: "cardPage",
    meta: {
      title: "1号奢仓"
    },
    component: () => import("./../views/cardPage/cardPage.vue")
  },
  {
    path: "/largeCarousel",
    name: "largeCarousel",
    meta: {
      title: "1号奢仓"
    },
    component: () => import("@/views/largeCarousel/index.vue")
  },
  {
    path: "/weixinOpen",
    name: "weixinOpen",
    meta: {
      title: "1号奢仓"
    },
    component: () => import("./../views/weixinOpen/index.vue")
  },
  {
    path: "/goods",
    name: "goods",
    meta: {
      title: "商品分享"
    },
    component: () => import("./../views/goodsShare/index.vue")
  }
];
const router = new VueRouter({
  routes
});

export default router;
