import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
import "./plugins/axios";
import VueWechatTitle from "vue-wechat-title";
import LuckDraw from "vue-luck-draw";
import Vant from "vant";
import "vant/lib/index.css";
import waterfall from "vue-waterfall2";
Vue.use(VueWechatTitle);
Vue.use(LuckDraw);
Vue.use(Vant);
Vue.use(waterfall);
console.log(process.env);
import "swiper/dist/css/swiper.min.css";
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
