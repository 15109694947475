import Vue from "vue";
import axios from "axios";
// 环境的切换

if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "https://t.yhc.ikuyoo.cn/index.php";
} else {
  axios.defaults.baseURL = "https://t.yhaocang.com/";
}
// 请求超时时间
axios.defaults.timeout = 10000;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";
// 请求拦截器

axios.interceptors.request.use(
  config => {
    config.params = {
      ...config.params,
      store_id: 1
    };
    return config;
  },
  error => {
    return Promise.error(error);
  }
);
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // return Promise.reject(error);
    return Promise.reject(error);
  }
);
Vue.prototype.axios = axios;
export { axios };
